<template>
  <div id="app">
    <!-- <router-view></router-view> -->
    <Paygfxt></Paygfxt>
  </div>
</template>

<script>
import Paygfxt from '@/views/paygfxt.vue'
export default {
  data() {
    return {}
  },
  components: {
    Paygfxt,
  },
}
</script>
<style></style>
