<template>
  <div class="app-container">
    <div>
      <!-- input框 隐藏的 -->
      <el-input
        input="jianchetx"
        id="wb"
        class="wb"
        v-model="allgoodsdata.upcCode"
        v-on:keyup.enter.native="search"
        placeholder="条形码/商品名称/商品拼音首字母"
        style="width: 500px; position: absolute; top: 10px; margin-top: 5px"
      />
    </div>
    <div class="main-con">
      <el-row :gutter="20">
        <el-col>
          <!-- 会员区域71px -->
          <!-- :style="{ height: windowWidth > 1000 ? '1700px' : '1145px' }" -->
          <div
            class="car"
            :style="{ height: windowWidth > 1000 ? '1771px' : '1216px' }"
          >
            <!-- 商品区域 -->
            <el-table
              :data="buyGoodsData"
              :show-header="false"
              class="tablegoods"
              :height="windowWidth > 1000 ? '1771px' : '1216px'"
              :style="tableStyle"
              style="overflow: auto"
              :cell-style="{ 'text-align': 'center' }"
            >
              <!-- 展示首页商品区域 -->
              <el-table-column slot="empty">
                <!-- 图片区域 -->
                <el-image
                  :style="{
                    width: '100%',
                    height: windowWidth > 1000 ? '1771px' : '1216px',
                  }"
                  src="http://test.pinbaiyun.com/goodslogin.png"
                />
              </el-table-column>
              <!-- 展示的是商品区域 -->
              <el-table-column prop="title" label="图片">
                <!-- <template slot-scope="scope"> -->
                  <el-image
                  :style="{
                    width: windowWidth > 1000 ? '200px' : '120px',
                    height: windowWidth > 1000 ? '200px' : '120px',
                  }"
                  :src="require('@/assets/load.jpg')"
                />
                <!-- </template> -->
              </el-table-column>
              <el-table-column prop="title" label="商品" width="450px">
                <template slot-scope="scope">
                  <el-row>{{ scope.row.productName }}</el-row>
                  <el-row>{{ scope.row.retailPrice }}/件</el-row>
                </template>
              </el-table-column>
              <el-table-column label="数量" width="200px">
                <template slot-scope="scope">
                  <el-button
                    icon="el-icon-minus"
                    circle
                    @click="handleNumChange(scope.$index, 'minus')"
                    disabled
                    v-if="scope.row.quantity == 1"
                  ></el-button>
                  <!-- 减数量 -->
                  <el-button
                    icon="el-icon-minus"
                    circle
                    @click="handleNumChange(scope.$index, 'minus')"
                    v-if="scope.row.quantity != 1"
                  ></el-button>
                  {{ scope.row.quantity }}
                  <!-- 加数量 -->
                  <el-button
                    icon="el-icon-plus"
                    circle
                    @click="handleNumChange(scope.$index, 'plus')"
                  ></el-button>
                </template>
              </el-table-column>
              <el-table-column label="价格" width="200px">
                <template slot-scope="scope">
                  ￥{{
                    (scope.row.quantity * scope.row.retailPrice).toFixed(2)
                  }}
                </template>
              </el-table-column>
              <el-table-column prop="options" label="操作">
                <template slot-scope="scope">
                  <el-button
                    type="danger"
                    icon="el-icon-delete"
                    @click="deleterow(scope.row, scope.$index)"
                  ></el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <!-- 下方结算区域 -->
          <div class="jiesuan">
            <el-row>
              <!-- 原价 合计 -->
              <el-col :span="12">
                <!-- 原价 -->
                <div
                  class="footergoods"
                  style="height: 70px; align-items: center"
                >
                  原价￥{{ oprice.toFixed(2) }}
                </div>
                <!-- 合计 -->
                <div
                  class="footergoods"
                  style="
                    border-top: 2px solid #d3d3d3;
                    height: 75px;
                    align-items: center;
                  "
                >
                  合计<span
                    style="color: red; font-size: 50px; font-weight: bolder"
                    >￥{{ price.toFixed(2) }}</span
                  >
                </div>
              </el-col>
              <!-- 取消交易区域 -->
              <el-col :span="4">
                <div
                  class="footergoods"
                  style="
                    height: 150px;
                    align-items: center;
                    background-color: whitesmoke;
                  "
                  @click="paycancel"
                >
                  取消交易
                </div>
              </el-col>
              <!-- 结算区域 -->
              <el-col :span="8">
                <div
                  class="footergoods"
                  style="
                    height: 150px;
                    align-items: center;
                    background-color: #00a8ff;
                    color: white;
                    margin-top: -1px;
                  "
                  @click="scanCode"
                  v-preventReClick
                >
                  扫码支付
                </div>
              </el-col>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- 支付成功/失败 -->
    <el-dialog
      title=""
      :visible.sync="pay_success"
      width="40%"
      top="20vh"
      :show-close="false"
      :fullscreen="true"
    >
      <div style="text-align: center; margin-top: 50%">
        <el-image
          style="width: 153px; height: 150px"
          src="https://gfxtqqg.oss-cn-hangzhou.aliyuncs.com/gfxt_pby/wx_app/icon/static/gif/paysuccess.png"
        ></el-image>
        <div
          style="
            color: #07b805;
            font-size: 30px;
            font-weight: bolder;
            margin-top: 4%;
          "
        >
          支付成功({{ countdown }})
        </div>
      </div>
      <div
        style="text-align: center; margin-top: 100px"
        @click="paysuccessclose"
      >
        <el-button style="font-size: 30px; width: 224px" type="primary" plain
          >继续购物</el-button
        >
      </div>
    </el-dialog>
    <!-- 失败 -->
    <el-dialog
      title=""
      :visible.sync="pay_fail"
      width="40%"
      top="20vh"
      :show-close="false"
    >
      <div style="text-align: center; margin-top: -5%">
        <el-image
          style="width: 153px; height: 150px"
          src="https://gfxtqqg.oss-cn-hangzhou.aliyuncs.com/gfxt_pby/wx_app/icon/static/gif/payfail.png"
        ></el-image>
        <div
          style="
            color: #d81e06;
            font-size: 30px;
            font-weight: bolder;
            margin-top: 4%;
          "
        >
          支付失败
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="pay_fail = false">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 支付 -->
    <el-dialog
      title=""
      :visible.sync="dialogVisibleapply"
      width="44%"
      top="26vh"
      :show-close="false"
      :fullscreen="true"
    >
      <div style="height: 300px">
        <el-button
          class="btnBack"
          type="primary"
          icon="el-icon-arrow-left"
          @click="fansaoclose"
        ></el-button>
      </div>
      <div style="text-align: center; font-size: 41px; color: #00a8ff">
        请出示微信、支付宝付款码，对准下方扫码器扫码付款
      </div>
      <div style="text-align: -webkit-center">
        <el-image
          style="width: 200px; height: 100px"
          src="https://gfxtqqg.oss-cn-hangzhou.aliyuncs.com/gfxt_pby/wx_app/icon/static/gif/timg.gif"
        ></el-image>
      </div>
      <div style="text-align: center" @click="saoma">
        <el-button style="font-size: 30px" type="primary" plain
          >扫码支付</el-button
        >
      </div>
    </el-dialog>
    <!-- 扫码支付 正扫 -->
    <el-dialog
      title=""
      :visible.sync="dialogVisibleapplypay"
      width="44%"
      top="10vh"
      :show-close="false"
      :fullscreen="true"
    >
      <div style="height: 300px">
        <!--  plain  -->
        <el-button
          type="primary"
          icon="el-icon-arrow-left"
          @click="saomaclose"
        ></el-button>
      </div>
      <div style="text-align: center; font-size: 41px; color: #00a8ff">
        请扫描下方二维码并完成支付({{ paytime }})
      </div>
      <div
        style="
          text-align: center;
          margin: 0 auto;
          margin-top: 50px;
          width: fit-content;
        "
      >
        <span id="qrcode"></span>
      </div>
    </el-dialog>
    <!-- 跳转页面警告 取消交易页面 -->
    <el-dialog title="" :visible.sync="paywarning" width="44%" top="10vh">
      <el-result icon="warning" title="警告提示" subTitle="">
        <template slot="extra">
          由于您长时间未操作{{ countdown }}秒钟后将取消交易
        </template>
      </el-result>
    </el-dialog>
  </div>
</template>

<script>
import QRcode from 'qrcodejs2/qrcode'
export default {
  components: {},
  data() {
    return {
      // token
      token: '',
      // 搜索全部商品的分页器
      allgoodsdata: {
        pageIndex: 1,
        pageSize: 10,
        // 条形码的搜索
        upcCode: '',
        // 搜索全部商品的关键词
        queryCondition: '',
        scId: '',
      },
      upcCodeData: [],
      buyGoodsData: [],
      // 仓库id 默认第一个仓库
      scIdone: '',
      // 商户id
      merchId: '',
      // 订单编号
      ordersn: '',
      orderid: '',
      // 零售订单的id
      retailId: '',
      // 付款码
      auth_code: '',
      auth_code_array: [],
      // 支付类型
      paytype: '',
      // 支付状态码
      payCode: '',
      // 查单的状态码
      findDDCode: '',
      // 预订单的参数
      advanceOrderData: {},
      // 本来的变量
      windowWidth: window.innerWidth,
      title: '111',
      description: '222',
      code: '333',
      discount: '444',
      expiry: '555',
      drawer: false,
      direction: 'btt',
      numReg: /^[0-9]*$/,
      pre_category1: 0,
      pre_category2: 0,
      payurl: '',
      paywarning: false,
      countdown: '300',
      paytime: '90',
      ordertime: '90',
      paytimer: null,
      timer: null,
      ordertimer: null,
      // 正扫 对话框
      dialogVisibleapplypay: false,
      checkList: [],
      goodkouwei: [],
      discount_p: 1,
      member: {},
      memberData: [],
      options: [
        {
          value: '1',
          label: '1',
        },
      ],
      cate_id: '',
      value: '',
      tableData: [],
      test: [],
      goodsData: [],
      pageSize: 16,
      total: 0,
      // 菜单
      menuGoods: [],
      menuPageSize: 0,
      menuTotal: 0,
      menuPage: 1,
      temp_data: [],
      discount_type: 10,
      dialogVisiblePayType: false,
      dialogVisibleDiscount: false,
      merchid: '',
      username: '',
      loading: false,
      code: '',
      name_code: '',
      code_array: [],
      unionid: '',
      openid: '',
      is_flag: 0,
      pay_success: false,
      pay_fail: false,
      sn: '',
      people_price: '',
      people: '',
      single_people_price: '',
      is_restaurant: '',
      do: 0,
      cates: [],
      // 支付界面
      dialogVisibleapply: false,
      paytype: 0,
      nickname: '微信用户',
      avator:
        'https://shadow.elemecdn.com/app/element/hamburger.9cf7b091-55e9-11e9-a976-7f4d0b07eef6.png',
      uuid: null,
    }
  },
  computed: {
    // 原价
    oprice() {
      return this.buyGoodsData.reduce((res, item) => {
        return (res += item.retailPrice * item.quantity)
      }, 0)
    },
    // 合计
    price() {
      return this.buyGoodsData.reduce((res, item) => {
        return (res += item.retailPrice * item.quantity)
      }, 0)
    },
    goosTotal() {
      let goosTotal = 0
      this.buyGoodsData.forEach((item) => {
        goosTotal += item.quantity
      })
      return goosTotal
    },
    tableStyle() {
      // 根据 windowWidth 的值来返回不同的 max-height
      return {
        maxHeight: this.windowWidth > 1000 ? '1771px' : '1216px',
      }
    },
    tableHeight() {
      // 根据 windowWidth 的值来返回不同的 max-height
      return {
        height: this.windowWidth > 1000 ? '1771px' : '1216px',
      }
    },
  },
  mounted() {
    // 拿到当前路径中的token 并请求商户id
    this.getMerchId()
    this.sendCode() // 触发倒计时
    var _this = this
    document.onkeydown = function () {
      var that = this
      if (event.keyCode != 13) {
        var bizCode = String.fromCharCode(event.keyCode)
        if (event.keyCode >= 48 && event.keyCode <= 122) {
          _this.auth_code_array.push(bizCode)
        }
        console.log(_this.auth_code_array)
      } else {
        _this.payScanCode()
      }
    }
  },
  methods: {
    // 获取商户id
    async getMerchId() {
      this.token = window.location.href.split('=')[1]
      const data = {
        token: this.token,
      }
      await this.$store.dispatch('reqMerchId', data)
      this.merchId = this.$store.state.paygfxt.merchid
      // 获取仓库id的接口
      this.getScIdList()
    },
    // 获取当前时间
    getCurrentDateTime() {
      const now = new Date()
      const year = now.getFullYear()
      const month = (now.getMonth() + 1).toString().padStart(2, '0')
      const day = now.getDate().toString().padStart(2, '0')
      const hours = now.getHours().toString().padStart(2, '0')
      const minutes = now.getMinutes().toString().padStart(2, '0')
      const seconds = now.getSeconds().toString().padStart(2, '0')
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
    },
    closed() {
      var inputElement = document.getElementById('wb')
      // 设置输入框为只读
      inputElement.readOnly = true
      // 尝试立即隐藏输入法
      // 这部分依赖于浏览器和输入类型，并不总是有效
      inputElement.blur() // 移出焦点
    },
    paysuccessclose() {
      clearInterval(this.paytimer)
      this.paytimer = null
      // 支付的对话框
      this.dialogVisibleapply = false
      // 正扫 对话框
      this.dialogVisibleapplypay = false
      this.pay_success = false
      this.tableData = []
      this.countdown = '300'
      this.paytime = '90'
    },
    // 点击返回按钮
    fansaoclose() {
      clearInterval(this.paytimer)
      this.paytimer = null
      // 支付的对话框
      this.dialogVisibleapply = false
      this.countdown = '300'
      // 触发倒计时
      this.sendCode()
      this.paytime = '90'
      var data = {
        id: this.orderid,
      }
      // 关闭订单
      // closeDd(data).then((response) => {
      //   console.log(response.message)
      // })
    },
    saomaclose() {
      this.paytime = '90'
      // 正扫 对话框
      this.dialogVisibleapplypay = false
      this.fansaoclose()
    },
    saoma() {
      this.paytime = '90'
      // 正扫 对话框
      this.dialogVisibleapplypay = true
      this.$nextTick(function () {
        document.getElementById('qrcode').innerHTML = ''
        var qrcode = new QRcode(document.getElementById('qrcode'), {
          text:
            'http://www.pinbaiyun.com/system/payment/payLottery?orderid=' +
            this.orderid +
            '&merchid=' +
            this.merchId,
          width: 256,
          height: 256,
          colorDark: '#000000',
          colorLight: '#ffffff',
          correctLevel: QRcode.CorrectLevel.H,
        })
        // 将二维码显示在页面上
        // qrcode.makeCode(response)
      })
      // 正扫 对话框
      this.dialogVisibleapplypay = true
    },
    // 取消交易   清空所有商品
    paycancel() {
      this.buyGoodsData = []
    },
    // 倒计时
    sendCode() {
      // this.countdown--; //启动定时器
      this.auth_code_array = []
      this.timer = setInterval(() => {
        this.$nextTick(
          (window.onload = function () {
            // 光标默认位置
            var oInput = document.getElementById('wb')
            if (oInput != document.activeElement) {
              // 判断是否获取焦点
              oInput.setAttribute('readonly', 'readonly') // 设置只读模式
              oInput.focus()
              setTimeout(() => {
                oInput.removeAttribute('readonly') // 移除只读模式
              }, 20)
            }
          })
        )
        // 创建定时器
        if (this.countdown === 0) {
          // 关闭定时器
          // clearInterval(this.timer)
          // this.timer = null
          this.countdown = 300
          // 支付的对话框
          this.dialogVisibleapply = false
          // 正扫 对话框
          this.dialogVisibleapplypay = false
          // axios.post("https://www.pinbaiyun.com/unmanned/close");
          // axios.post('https://test.pinbaiyun.com:8443/unmanned/close?userUuid=' + this.uuid)
          // this.$router.push({
          //   path: '/wxoauth?merchid=' + this.merchid,
          // })
          // } else if (this.countdown === 10) {
          // this.paywarning = true
          //   this.countdown--
        } else {
          this.countdown--
          // console.log(this.countdown)
        }
      }, 1000)
    },
    paycode(receiptData) {
      // let num = 0
      // 启动定时器
      this.paytimer = setInterval(async () => {
        // 创建定时器
        if (this.paytime === 0) {
          // 关闭定时器
          clearInterval(this.paytimer)
          this.paytimer = null
          this.paytime = '90'
          // 支付的对话框
          this.dialogVisibleapply = false
          // 正扫 对话框
          this.dialogVisibleapplypay = false
          // 触发倒计时
          this.sendCode()
        }
        // else if (this.paytime % 3 == 0 && this.ordersn != '') {
        // console.log(this.ordersn)
        // const findDdData = {
        //   ordersn: this.ordersn,
        // }
        // if (findDdData.ordersn == '') {
        //   return
        // }
        // await this.$store.dispatch('reqFindDd', findDdData)
        // this.findDDCode = this.$store.state.paygfxt.findDDCode
        // if (this.findDDCode == '200') {
        //   clearInterval(this.paytimer)
        //   this.paytimer = null
        //   this.findDDCode = ''
        //   this.ordersn = ''
        //   // 正在支付界面关闭
        //   this.dialogVisibleapply = false
        //   // 正扫 对话框
        //   this.dialogVisibleapplypay = false
        //   // 调用倒计时
        //   this.sendCode()
        //   this.paytime = '90'
        //   // 商品数组清空
        //   this.buyGoodsData = []
        //   this.auth_code_array = []
        //   this.auth_code = ''
        //   if (num == 0) {
        //     this.$message({
        //       message: '支付成功',
        //       type: 'success',
        //     })
        //     num++
        //   }
        //   // 打印小票
        //   // console.log(receiptData, 'receiptData')
        //   // Android.showToast(JSON.stringify(receiptData))
        // }
        // this.paytime--
        // }
        else {
          this.paytime--
        }
      }, 1000)
    },
    // 结算 预订单接口
    async scanCode() {
      // 查单接口
      this.countdown = 300
      clearInterval(this.timer)
      this.timer = null
      if (this.buyGoodsData.length > 0) {
        // 支付界面的展示
        // this.dialogVisibleapply = true
        const data = []
        this.buyGoodsData.forEach((item) => {
          data.push({
            productId: item.id,
            oriPrice: item.retailPrice,
            taxPrice: item.retailPrice,
            discountRate: 0,
            orderNum: item.quantity,
          })
        })
        // 仓库id
        this.scIdone = this.$store.state.paygfxt.scIdList[0].id
        // 商户id
        this.merchId = this.$store.state.paygfxt.merchid
        // 当前时间
        const date = this.getCurrentDateTime()
        // 整理预订单接口的参数
        const advanceOrderData = {
          scId: this.scIdone,
          products: data,
          paymentDate: new Date().toISOString().slice(0, 10),
          // paymentDate: date,
          price: this.price,
          merchid: this.merchId,
          type: 5,
          // 整单折扣率
          discountRatio: 100,
          // 整单折扣金额
          discountAmount: 0,
        }
        this.advanceOrderData.date = date
        // 调用预订单接口
        await this.$store.dispatch('reqAdvanceOrder', advanceOrderData)
        this.ordersn = this.$store.state.paygfxt.ordersn
        this.orderid = this.$store.state.paygfxt.orderid
        this.retailId = this.$store.state.paygfxt.retailId
        this.auth_code_array = []
        // 预订单之后开始查单
        // 整理打印小票的参数
        // 处理打印小票的商品数组
        const receiptOrder = []
        this.buyGoodsData.forEach((item) => {
          receiptOrder.push({
            title: item.productName,
            // 单个的价格
            price: item.retailPrice.toFixed(2),
            total: item.quantity,
            // 数量的应收
            total_price: item.retailPrice.toFixed(2) * item.quantity,
          })
        })
        const receiptData = {
          // 店铺名称
          merchname: '小福星数字生活超市(仓小福)',
          // 预订单时间
          create_time: this.advanceOrderData.date,
          // 订单号
          ordersn: this.ordersn,
          // 打印小票的商品数组
          goods: receiptOrder,
          // 总数量
          goodstotal: this.goosTotal,
          // 总价格
          goodsprice: this.oprice.toFixed(2),
          // 支付类型
          paytype: this.paytype,
          // 原价
          oprice: this.oprice.toFixed(2),
          // 优惠金额
          coupon_price: 0,
          // 实付
          price: this.price.toFixed(2),
        }
        console.log(receiptData, 'receiptData')
        // 定时器
        this.paycode(receiptData)
        // 查单
        this.findDD(receiptData)
        this.saoma()
      } else {
        this.$message({
          message: '请扫描商品条码，添加商品',
          type: 'warning',
        })
        // 调用倒计时
        // this.sendCode()
      }
    },
    // 反扫
    async payScanCode() {
      return console.log('关闭')
      // 开始扫付款码
      this.paytype = 0
      if (
        this.auth_code_array.length == 18 &&
        // 支付的对话框
        this.dialogVisibleapply === true
      ) {
        // 支付
        console.log('进支付')
        // 支付宝
        if (this.auth_code_array[0] == 2) {
          this.paytype = 2
          // 微信
        } else {
          this.paytype = 1
        }
        console.log(11, this.auth_code_array)
        var authcode = ''
        for (var i = 0; i < this.auth_code_array.length; i++) {
          authcode += this.auth_code_array[i]
        }
        // 整理反扫的接口
        // 反扫接口的参数
        const reqAlipayAndWechatPayData = {
          // 商户id
          merchid: this.merchId,
          paytype: this.paytype,
          price: this.price,
          // 二维码的数字
          auth_code: authcode,
          // 订单编号
          ordersn: this.ordersn,
        }
        console.log(reqAlipayAndWechatPayData, 'reqAlipayAndWechatPayData')
        await this.$store.dispatch(
          'reqAlipayAndWechatPay',
          reqAlipayAndWechatPayData
        )
        this.payCode = this.$store.state.paygfxt.payCode
        console.log(this.payCode, 'this.payCode')
        var msg = this.$store.state.paygfxt.msg
        // const findDdData = {
        //   ordersn: this.ordersn,
        // }
        // 处理打印小票的商品数组
        const receiptOrder = []
        this.buyGoodsData.forEach((item) => {
          receiptOrder.push({
            title: item.productName,
            // 单个的价格
            price: item.retailPrice.toFixed(2),
            total: item.quantity,
            // 数量的应收
            total_price: item.retailPrice.toFixed(2) * item.quantity,
          })
        })
        // 打印小票的总数量
        // 整理打印小票的参数
        const receiptData = {
          // 店铺名称
          merchname: '小福星数字生活超市(仓小福)',
          // 预订单时间
          create_time: this.advanceOrderData.date,
          // 订单号
          ordersn: this.ordersn,
          // 打印小票的商品数组
          goods: receiptOrder,
          // 总数量
          goodstotal: this.goosTotal,
          // 总价格
          goodsprice: this.oprice.toFixed(2),
          // 支付类型
          paytype: this.paytype,
          // 原价
          oprice: this.oprice.toFixed(2),
          // 优惠金额
          coupon_price: 0,
          // 实付
          price: this.price.toFixed(2),
        }
        // this.$message({
        //   message: msg,
        //   type: this.payCode == '200' ? 'success' : 'error',
        // })
        // if (this.payCode == 200) {
        // 正在支付界面关闭
        // this.dialogVisibleapply = false
        // 调用倒计时
        // this.sendCode()
        // 商品数组清空
        // this.buyGoodsData = []
        // 打印小票
        // Android.showToast(JSON.stringify(receiptData))
        // } else {
        // if (findDdData.ordersn == '') {
        //   console.log(findDdData.ordersn, 111)
        //   return
        // }
        // let findddId = setInterval(async () => {
        //   this.$store.dispatch('reqFindDd', findDdData)
        //   this.findDDCode = this.$store.state.paygfxt.findDDCode
        //   if (this.findDDCode === '200') {
        //     // findddId = null
        //     // 正在支付界面关闭
        //     this.dialogVisibleapply = false
        //     // 调用倒计时
        //     this.sendCode()
        //     // 商品数组清空
        //     this.buyGoodsData = []
        //     this.$message({
        //       message: '支付成功',
        //       type: 'success',
        //     })
        //     clearInterval(findddId)
        //     // 打印小票
        //     console.log(receiptData, 'receiptData')
        //     Android.showToast(JSON.stringify(receiptData))
        //   }
        //   console.log(this.dialogVisibleapply)
        //   // 支付的对话框
        //   if (!this.dialogVisibleapply) {
        //     clearInterval(findddId)
        //   }
        // }, 3000)
        // }
        // 清空二维码数组
        // this.auth_code_array = []
        // // 清空二维码的数字字符
        // this.auth_code = ''
        // payWxQrcode(data).then((response) => {
        //   console.log(response)
        //   if (response.info == 2) {
        //     console.log('支付成功')
        //   } else if (response.info == 1) {
        //     console.log('等待支付')
        //     // this.ordertime = this.paytime;
        //     var dingdan = {
        //       id: this.orderid,
        //     }
        //     this.ordertimer = setInterval(() => {
        //       // 创建定时器
        //       if (this.paytime === 0) {
        //         // 关闭定时器
        //         clearInterval(this.ordertimer)
        //         this.ordertimer = null
        //         // closeDd(dingdan).then(response=>{
        //         //   console.log(response.message)
        //         // })
        //       } else if (this.paytime % 5 == 0) {
        //         findDd(dingdan).then((response) => {
        //           console.log(response)
        //           if (response.code == 200) {
        //             simpleTest({ id: this.orderid })
        //               .then((res) => {
        //                 console.log(res)
        //                 console.log(JSON.stringify(res.info))
        //                 Android.showToast(JSON.stringify(res.info))
        //               })
        //               .catch((res) => {
        //                 console.log(res)
        //               })
        //             clearInterval(this.ordertimer)
        //             this.ordertimer = null
        //           } else {
        //             console.log('失败')
        //             this.auth_code = ''
        //             this.auth_code_array = []
        //           }
        //           // this.ordertime --;
        //         })
        //       }
        //       console.log('ordertime', this.paytime)
        //     }, 1000)
        //   } else {
        //     console.log('支付失败')
        //   }
        //   this.auth_code = ''
        //   this.auth_code_array = []
        // })
        this.allgoodsdata.upcCode = ''
      }
      this.auth_code = ''
      this.auth_code_array = []
    },
    // 查单
    async findDD(receiptData) {
      // let num = 0
      const findDdData = {
        ordersn: this.ordersn,
      }
      if (findDdData.ordersn == '') {
        return
      }
      await this.$store.dispatch('reqFindDd', findDdData)
      this.findDDCode = this.$store.state.paygfxt.findDDCode
      if (this.findDDCode == '200') {
        clearInterval(this.paytimer)
        this.paytimer = null
        this.findDDCode = ''
        this.ordersn = ''
        // 正在支付界面关闭
        this.dialogVisibleapply = false
        // 正扫 对话框
        this.dialogVisibleapplypay = false
        // 调用倒计时
        this.sendCode()
        this.paytime = '90'
        // 商品数组清空
        this.buyGoodsData = []
        this.auth_code_array = []
        this.auth_code = ''
        // if (num == 0) {
        this.$message({
          message: '支付成功',
          type: 'success',
        })
        //   num++
        // }
        // 打印小票
        // console.log(receiptData, 'receiptData')
        Android.showToast(JSON.stringify(receiptData))
      } else {
        // 不是200 就重新查单
        setTimeout(() => {
          this.findDD(receiptData)
        }, 2000)
      }
    },
    // 获取仓库id的接口
    async getScIdList() {
      const data = {
        pageIndex: 1,
        pageSize: 15,
        merchId: this.merchId,
      }
      try {
        await this.$store.dispatch('reqScIdList', data)
        this.scIdList = this.$store.state.paygfxt.scIdList
        // 仓库id
        this.scIdone = this.$store.state.paygfxt.scIdList[0].id
      } catch (err) {
        return false
      }
    },
    // 搜索商品 直接添加
    search() {
      console.log('serach')
      // 倒计时 整个页面倒计时
      this.countdown = 300
      this.search_goods()
    },
    // 搜索商品展示
    async search_goods() {
      if (this.allgoodsdata.upcCode && !this.dialogVisibleapply) {
        // 正在搜索中
        this.loading = true
        this.allgoodsdata.merchId = this.merchId
        this.allgoodsdata.scId = this.scIdone
        console.log(this.allgoodsdata, 'this.allgoodsdata')
        // 搜搜商品接口
        await this.$store.dispatch('reqCodegoods', this.allgoodsdata)
        this.upcCodeData = this.$store.state.paygfxt.upcCodeData
        if (this.upcCodeData) {
          const indexArr = this.buyGoodsData.filter((item) => {
            return item.externalCode == this.upcCodeData.externalCode
          })
          if (indexArr.length > 0) {
            this.buyGoodsData.forEach((item) => {
              if (item.externalCode == this.upcCodeData.externalCode) {
                item.quantity++
              }
            })
          } else {
            this.$set(this.upcCodeData, 'quantity', 1)
            this.buyGoodsData.push(this.upcCodeData)
          }
        } else {
          this.$message({
            message: '未能找到该商品，请联系工作人员',
            type: 'warning',
          })
        }
        // 扫完之后条码清空
        this.allgoodsdata.upcCode = ''
        this.auth_code_array = []
      }
    },
    handleNumChange(index, method) {
      this.countdown = '300'
      // var quantity = parseInt(this.buyGoodsData[index].quantity)
      if (method == 'plus') {
        this.buyGoodsData[index].quantity += 1
        // if (this.buyGoodsData[index].total > quantity) {
        // quantity += 1
        // } else {
        //   this.$message({
        //     message: '没有更多了！',
        //     type: 'error',
        //   })
        // }
      }
      if (method == 'minus') {
        if (this.buyGoodsData[index].quantity > 1) {
          this.buyGoodsData[index].quantity -= 1
        }
      }
      // input框的
      var oInput = document.getElementById('wb')
      if (oInput != document.activeElement) {
        // 判断是否获取焦点
        oInput.setAttribute('readonly', 'readonly') // 设置只读模式
        oInput.focus()
        setTimeout(() => {
          oInput.removeAttribute('readonly') // 移除只读模式
        }, 20)
      }
    },
    // 删除当前这个商品
    deleterow(row, index) {
      this.countdown = '300'
      this.buyGoodsData.splice(index, 1)
      this.$nextTick(
        (window.onload = function () {
          // 光标默认位置
          var oInput = document.getElementById('wb')
          if (oInput != document.activeElement) {
            // 判断是否获取焦点
            oInput.setAttribute('readonly', 'readonly') // 设置只读模式
            oInput.focus()
            setTimeout(() => {
              oInput.removeAttribute('readonly') // 移除只读模式
            }, 20)
          }
        })
      )
    },
  },
}
</script>
<style>
.el-col {
  padding: 0px !important;
}
.el-row {
  margin: 0px !important;
}
.el-table__empty-block {
  display: block !important;
  width: 100% !important;
}
.el-table__body {
  width: revert !important;
}

.el-table--scrollable-x .el-table__body-wrapper {
  overflow: visible !important;
}
.el-table--scrollable-y {
  overflow-y: scroll;
}
.el-image {
  display: block !important;
}
.el-table {
  height: 100%;
}
/* 结算区域 */
.jiesuan {
  border: 0;
  background-color: white;
}
</style>
<style lang="scss" scoped>
.jie2 {
  float: right;
  margin-top: 10%;
  margin-right: -61%;
  margin-bottom: 6%;
}

.heji {
  float: right;
  margin-right: 2%;
  margin-top: 1%;
  font-size: 18px;
}

.goods-name {
  position: absolute;
  margin-top: -18%;
  margin-left: 25%;
  width: 64%;
}

.jie {
  float: right;
  margin-right: -59%;
  // margin-top: 5%;
  margin-bottom: 6%;
}

.menus {
  background: #ffffff;
  padding: 10px;
}

.menus > .el-col-6 {
  border-radius: 4px;
  background: #f5f7f9;
}

.item > .el-image {
  cursor: pointer;
  height: 100px;
}

.item > .title {
  height: 14.95px;
  overflow: hidden;
}

.tablegoods {
  font-size: 23px;
  font-weight: 700;
}

.footergoods {
  justify-content: center;
  display: flex;
  align-items: baseline;
  font-size: 28px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
  height: 110px;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.app-container {
  background: #eeeff3;
  padding: 0;
  margin: 0;
}

.main-con {
  margin-top: 0px;
  padding: 0px;
}

.el-row {
  margin: 0;
}

.item > .title {
  font-size: 13px;
}

.item > .price {
  font-size: 13px;
  color: red;
  text-align: center;
}

.addOne {
  font-size: 13px;
  text-align: center;
  border: 1px solid gray;
}

.car {
  // overflow: auto;
  font-size: 20px;
}

.el-checkbox-button {
  margin-right: 10px;
}

.el-checkbox-button__inner {
  border-left: none;
}

.youhui {
  justify-content: center;
  align-items: center;
  height: 70px;
  display: flex;
}

.coupon {
  margin: 1%;
  width: 98%;
  height: 128px;
  border-radius: 10px;
  filter: drop-shadow(0px 0px 4px black);
  //filter: drop-shadow(0px 0px 1px black);
  background: radial-gradient(circle at right top, transparent 15px, #00a8ff 0)
      top left/30% 50% no-repeat,
    radial-gradient(circle at right bottom, transparent 15px, #00a8ff 0) bottom
      left/30% 50% no-repeat,
    radial-gradient(circle at left top, transparent 15px, #eeeeee 0) top
      right/70% 50% no-repeat,
    radial-gradient(circle at left bottom, transparent 15px, #eeeeee 0) bottom
      right/70% 50% no-repeat;
}
.couponleft {
  font-size: 23px;
  text-align: center;
  color: aliceblue;
  width: 30%;
  height: 128px;
  font-weight: bold;
  // display: flex;
  // align-items: center;
  // border: 1px solid black;
}
.couponright {
  font-size: 17px;
  padding-left: 59px;
  text-align: left;
  width: 60%;
  height: 128px;
}
.xian {
  font-size: 14px;
  color: dimgrey;
}
.couponbtt {
  width: 10%;
  height: 128px;
  display: flex;
  // align-items: center;
}
.couponnum {
  display: flex;
  align-items: center;
  contain: inline-size;
}
.avatar {
  // 父容器
  width: 400px;
  height: 400px;
  overflow: hidden;
  position: relative;

  // 角标
  .angle_mark {
    position: absolute;
    top: -50px;
    right: -50px;
    background-color: #00a8ff;
    width: 100px;
    height: 100px;
    transform: rotate(45deg);
    // 角标文字
    span {
      position: absolute;
      display: inline-block;
      color: #fff;
      width: 100%;
      bottom: 6px;
      left: 0;
      text-align: center;
    }
  }
}

// input框隐藏
.wb {
  margin-top: 5px;
}
</style>
