import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false
Vue.use(ElementUI)

// 自定义指令
Vue.directive('preventReClick', {
  inserted: function (el, binding) {
    el.addEventListener('click', () => {
      if (!el.disabled) {
        el.disabled = true
        el.style.cursor = 'not-allowed'
        setTimeout(() => {
          el.disabled = false
          el.style.cursor = 'pointer'
        }, binding.value || 3000)
      }
    })
  },
})

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
