import requests from '@/utils/request'
// 获取商户id
export const reqMerchId = (data) => {
  return requests({
    url: '/merch/getMerchIdByToken',
    method: 'get',
    params: data,
  })
}

// 获取所有商品的信息
export const reqAllgoods = (data) => {
  return requests({
    url: '/basedata/product/queryRetailForOpen',
    method: 'post',
    data,
  })
}
// 仓库id的接口
export const reqScIdList = (data) => {
  return requests({
    url: '/open/selector/sc',
    method: 'post',
    data,
  })
}

// 预订单接口
export const reqAdvanceOrder = (data) => {
  return requests({
    url: '/index/order/preOrderNew',
    method: 'post',
    data,
    baseURL: '/shopapi',
  })
}

// 反扫 微信和支付宝的接口
export const reqAlipayAndWechatPay = (data) => {
  return requests({
    url: '/system/payment/payWxQrcodes',
    method: 'get',
    params: data,
    baseURL: '/shopapi',
  })
}

// 调订单同步接口
export const reqFindDd = (data) => {
  return requests({
    url: '/system/payment/findDd',
    method: 'get',
    params: data,
    baseURL: '/shopapi',
  })
}
