import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

// 引入小仓库
import paygfxt from './paygfxt/index'

export default new Vuex.Store({
  //大仓库需要注册全部小仓库
  //vuex新增的一个配置项:模块式开发.右侧V也是对象
  modules: {
    paygfxt,
  },
})
