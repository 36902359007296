import {
  reqAllgoods,
  reqAdvanceOrder,
  reqAlipayAndWechatPay,
  reqScIdList,
  reqFindDd,
  reqMerchId,
} from '@/api/paygfxt'

const state = {
  // 商户id
  merchid: '',
  // 所有商品的数据
  allGoodsDatas: [],
  totalCount: 0,
  totalPage: 0,
  traceId: '',
  // 仓库id
  scIdList: [],
  // 仓库第一个id
  scIdone: '',
  // 订单编号
  ordersn: '',
  orderid: '',
  // 零售订单的id
  retailId: '',
  // 支付成功和失败的标识
  payCode: 0,
  msg: '',
  // 条形码的商品
  upcCodeData: {},
  // 查单的code
  findDDCode: '',
}

const mutations = {
  SET_MERCHID(state, merchid) {
    state.merchid = merchid
  },
  SET_ALLGOODSDATAS(state, allGoodsDatas) {
    state.allGoodsDatas = allGoodsDatas
  },
  SET_TOTALCOUNT(state, totalCount) {
    state.totalCount = totalCount
  },
  SET_TOTALPAGE(state, totalPage) {
    state.totalPage = totalPage
  },
  SET_TRACEID(state, traceId) {
    state.traceId = traceId
  },
  SET_SCIDLIST(state, scIdList) {
    state.scIdList = scIdList
  },
  SET_ORDERSN(state, ordersn) {
    state.ordersn = ordersn
  },
  SET_ORDERID(state, orderid) {
    state.orderid = orderid
  },
  SET_RETAILID(state, retailId) {
    state.retailId = retailId
  },
  SET_PAYCODE(state, payCode) {
    state.payCode = payCode
  },
  SET_PAYMSG(state, msg) {
    state.msg = msg
  },
  SET_UPCCODE(state, upcCodeData) {
    state.upcCodeData = upcCodeData
  },
  SET_FINDDDCODE(state, findDDCode) {
    state.findDDCode = findDDCode
  },
  SER_SCIDONE(state, scIdone) {
    state.scIdone = scIdone
  },
}

const actions = {
  // 请求商户id的接口
  async reqMerchId({ commit, state, dispatch }, data) {
    const result = await reqMerchId(data)
    // console.log(result, 'reqMerchId')
    if (result.code === 200) {
      commit('SET_MERCHID', result.data)
      return 'ok'
    } else {
      return Promise.reject(new Error(result.message))
    }
  },
  // 请求仓库id的接口
  async reqScIdList({ commit, state, dispatch }, data) {
    const result = await reqScIdList(data)
    // console.log(result, '仓库id')
    if (result.code === 200) {
      commit('SET_SCIDLIST', result.data.datas)
      commit('SER_SCIDONE', result.data.datas[0].id)
      return 'ok'
    } else {
      return Promise.reject(new Error(result.message))
    }
  },
  // 请求所有商品的接口
  // async reqAllgoods({ commit, state, dispatch }, data) {
  //   const result = await reqAllgoods(data)
  //   console.log(result, 'allgoods')
  //   if (result.code === 200) {
  //     commit('SET_ALLGOODSDATAS', result.data.datas)
  //     commit('SET_TOTALCOUNT', result.data.totalCount)
  //     commit('SET_TOTALPAGE', result.data.totalPage)
  //     commit('SET_TRACEID', result.traceId)
  //     return 'ok'
  //   } else {
  //     return Promise.reject(new Error(result.message))
  //   }
  // },
  // 请求条形码的商品的接口
  async reqCodegoods({ commit, state, dispatch }, data) {
    const result = await reqAllgoods(data)
    // console.log(result, 'codegoods')
    if (result.code === 200) {
      commit('SET_UPCCODE', result.data.datas[0])
    }
  },

  // 请求预订单接口
  async reqAdvanceOrder({ commit, state, dispatch }, data) {
    const result = await reqAdvanceOrder(data)
    // console.log(result)
    if (result.code === 200) {
      commit('SET_ORDERSN', result.data.ordersn)
      commit('SET_RETAILID', result.data.retail_id)
      commit('SET_ORDERID', result.data.orderid)
      return 'ok'
    } else {
      return Promise.reject(new Error(result.message))
    }
  },
  // 反扫支付宝和微信支付接口
  async reqAlipayAndWechatPay({ commit, state, dispatch }, data) {
    const result = await reqAlipayAndWechatPay(data)
    // console.log(result)
    commit('SET_PAYCODE', result.code)
    commit('SET_PAYMSG', result.message)
  },
  // 调订单同步接口
  async reqFindDd({ commit, state, dispatch }, data) {
    const result = await reqFindDd(data)
    // console.log(result, 'reqFindDd')
    commit('SET_FINDDDCODE', result.code)
  },
}

const getters = {}

export default {
  state,
  mutations,
  actions,
  getters,
}
