import axios from 'axios'
import { Message } from 'element-ui'

const requests = axios.create({
  baseURL: '/api',
  timeout: 50000,
})

requests.interceptors.request.use((config) => {
  const authorization = localStorage.getItem('TOKEN')
  if (authorization) {
    config.headers['X-Auth-Token'] = authorization
  }
  return config
})

requests.interceptors.response.use(
  (res) => {
    return res.data
  },
  (err) => {
    if (err.response.status !== 200) {
      Message.error(err.response.data.msg)
    }
    return Promise.reject(err)
  }
)

export default requests
